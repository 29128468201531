.data-table-page {
    padding: 10px 10px;
    box-shadow: 0 0 0.2rem 0 rgba(215, 214, 213, 0.3);
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    width: 100vw;
    margin: 15px 0px;
    margin-left: 25px;
    margin-right: 20px;
    height: calc(85vh - 100px);
}

.data-table-section{
    margin: 10px 0px;
    padding: 10px 10px;
    padding-bottom: 20px;
    height: 75%;
}

.data-table-actions-sections{
    padding: 1px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
 .data-table-page-title{
    font-size: 20px;
    font-weight: bold;
}

/*------- broadcast form styling ------- */
.broadcast-info{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 14px;
}

.broadcast-contacts-input-section{
    border: 2px rgb(127, 127, 127) dotted;
    padding: 5px;
}

#import-label {
    display: inline-block;
    background: #f1f1f1;
    height: 60px;
    width: 100%;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
    border: 1px rgb(155, 155, 155) dashed;
    padding-top: 5px;
    font-size: 14px;
    font-weight: bold;
}

.broadcast-contacts-table{
    border-radius: 5px;
    margin-top: 10px;
}

.custom-table-broadcast .ant-table-thead > tr > th {
    font-size: 12px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.4);
    padding: 5px 16px; 
  }

.custom-table-broadcast .ant-table-tbody > tr > td {
    font-size: 12px;
    font-weight: bold;
    color: #1b1b1b; 
    padding: 6px 16px; 
  }

/*------- template form styling ------- */

.create-whatsapp-template-modal{
    display: flex;
    justify-content: space-between;
}

.template-broadcast-dialog{
    height: 74vh;
    width: 75%;
    overflow-y: scroll !important;
}

.template-dialog-inputs{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 10px;
}

.template-dialog-inputs-title{
    margin-bottom: 15px;
}


.add-buttons-btn{
    background-color: #00B0E3;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 4px 10px;
    width: max-content;
    font-size: 13px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 9px;
  }

  .add-buttons-btn-disabled{
    background-color: #00B0E3;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 4px 10px;
    width: max-content;
    font-size: 13px;
    cursor: default;
    display: flex;
    align-items: center;
    margin-bottom: 9px;
    opacity: 0.2;
  }

  .template-buttons-list{
    display: flex;
  }

  .added-temp-btns{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 10px;
    width: 100%;
  }

  #remove-temp-button{
    background: red;
    width: 20px !important;
    height: 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 7px 10px;
    color: white;
    cursor: pointer;
  }

 

/*------- table status values styling------- */
#status-RECEIVED{
    background-color: #22c55e3a;
    color: #22c55e;
    font-weight: bold;
    padding: 2px 5px;
    border-radius: 10px;
    font-size: 11px;
    width: 80px;
    text-align: center;
    text-transform: capitalize !important;
}


#status-SENT {
    background-color: #eab2083f;
    color: #eab308;
    font-weight: bold;
    padding: 2px 5px;
    border-radius: 10px;
    font-size: 11px;
    width: 80px;
    text-align: center;
    text-transform: capitalize !important;
}

#direction-INBOUND {
    background-color: #4463ef3f;
    color: #4455ef;
    font-weight: bold;
    padding: 2px 5px;
    border-radius: 10px;
    font-size: 11px;
    width: 100px;
    text-align: center;
    text-transform: capitalize !important;
}

#direction-OUTBOUND {
    background-color: #ef44443f;
    color: #ef4444;
    font-weight: bold;
    padding: 2px 5px;
    border-radius: 10px;
    font-size: 11px;
    width: 100px;
    text-align: center;
    text-transform: uppercase  !important;
}

.create-modal-content {
    background-color: #ffff;
    margin: 20px auto;
    height: auto;
    width: 90%;
    border-radius: 15px;
    animation: show-modal .6s ease-in-out;
    z-index: 7;
    display: grid;
    grid-template-rows: repeat(auto-fit, minmax(20px, 1fr));
    grid-gap: 10px;
}

.view-modal-content {
    margin-top: 20px !important;
    background-color: #ffff;
    margin: auto auto;
    width: 50%;
    height: auto;
    padding: 10px 20px;
    border-radius: 10px;
    animation: show-modal .7s ease-in-out;
    display: grid;
    grid-template-rows: repeat(auto-fit, minmax(20px, 1fr));
    grid-gap: 10px;
    z-index: 7;
}

.create-modal-inputs,
.view-modal-inputs {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 15px;
}

.modal-title {
    font-size: 18px;
    padding: 2px 0px;
    margin-bottom: 10px;
    opacity: 0.7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
}


/* DatePickerStyles.css Example custom styling  */
 .date-range-container{
    display: flex;
   justify-content: right;
}

.date-range-section{
    display: flex;
    align-items: center;
}

#date-range-trigger{
    cursor: pointer;
}

#date-range-header{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0 !important;
    color: rgba(0, 0, 0, 0.917);
    margin-right: 5px;
}


.featured-custom-datepicker {
    font-family: 'Nunito', sans-serif;
  }
  
.featured-custom-datepicker .react-datepicker__header {
    background-color: #00B0E3;
    color: #fff !important;
  }
  
.featured-custom-datepicker .react-datepicker__day {
    color: #333;
  }
  
.featured-custom-datepicker .react-datepicker__day--selected {
    background-color: #00B0E3;
    color: #fff;
  }

.featured-custom-datepicker input {
    padding: 5px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }


.contacts-table-actions{
    display: flex;
    justify-content: space-between;
  }


/* ant design custom styling */
.custom-table .ant-table-thead > tr > th {
    font-size: 14px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.4);
    padding: 9px 16px;
  }

.custom-table .ant-table-tbody > tr > td {
    font-size: 14px;
    font-weight: bold;
    color: #1b1b1b; 
    padding: 9px 16px; 
  }

.custom-pagination{
    font-size: 13px;
    font-weight: 600;
}
.custom-pagination .ant-select-selector {
  padding: 5px 10px !important;
  font-size: 13px; /* Adjust the padding as needed */
  height: 20px; /* Adjust as needed */
  line-height: 20px; /* Match the height */
}

.custom-pagination .ant-pagination-item {
  height: 20px; /* Adjust as needed */
  line-height: 20px; /* Match the height */
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Adjust the ellipsis height (if applicable) */
.custom-pagination .ant-pagination-item-ellipsis {
  line-height: 20px; /* Match the height of other elements */
}