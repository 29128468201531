
.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 6px;
}

.form-label {
    font-size: 14px !important;
    color: rgb(38, 39, 39);
    font-weight: 600 !important;
    padding-bottom: 0px !important;
}

.form-control {
    padding: 6px 5px;
    border-radius: 6px;
    border: 1px solid rgb(206, 206, 206);
    font-size: 14px !important;
    background-color: rgba(219, 219, 219, 0.322);
}

.form-control:focus {
    outline: none;
    border-color: rgb(40, 146, 233);
    background-color: rgb(255, 255, 255);
}

#error {
    font-size: 12px;
    color: rgb(221, 34, 34);
    margin-top: 5px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}


/* broadcast Upload contacts modal */

#file-import-label {
    display: inline-block;
    background: #f1f1f1;
    width: 100%;
    cursor: pointer;
    border-radius: 5px;
    border: 1px rgb(155, 155, 155) dashed;
    padding: 7px 7px;
    font-size: 13px;
    font-weight: bold;
}
/* end */

.uploading-indicator{
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-top: 3px;
    color: rgb(0, 70, 128);
}